// ヘッダー
.site-header {
  overflow: hidden;
  background: $dark;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 30px;
  z-index: 100;

  &__inner {
    width: 100%;
    height: 100%;
    max-width: $content-max-width;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
}

.site-name {
  max-width: 280px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  margin: 0;

  svg {
    height: 20px;
    width: auto;
  }
}

.site-menu {
  display: flex;
  flex-grow: 1;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    transition: all 0.3s ease-in;
    text-decoration: none;
    max-width: 280px;
    width: 33.33%;
    height: 100%;

    &:hover {
      background-color: var(--gray);
    }
    &:active {
      background-color: var(--gray2);
      fill: var(--dark);
      color: var(--dark);
    }

    &[href='#top'] {
      display: none;
    }
  }

  svg {
    height: calc((12 / 768) * 100vw);
    width: auto;
    fill: var(--white);
    color: var(--white);
  }
}

@media (min-width: 560px) {
  .site-name {
    width: 20%;
  }
  .site-menu {
    a {
      width: 25%;

      &[href='#top'] {
        display: flex;
      }
    }
  }
}

// 768px以上
@media (min-width: 768px) {
  .site-header {
    height: 60px;
  }
  .site-name {
    svg {
      height: 40px;
    }
  }
}

// 1400px 以上
@media (min-width: 1400px) {
  .site-menu {
    svg {
      height: 20px;
    }
  }
}
