// フッター

.site-footer {
  z-index: 1;
  position: relative;
  background-color: var(--gray);
  overflow: hidden;

  a {
    color: var(--white);
    display: inline-block;

    svg {
      transition: fill 0.3s ease-out;
    }

    &:hover {
      svg {
        fill: var(--gray2);
      }
    }

    &:active {
      svg {
        fill: var(--dark);
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin: calc((160 / 1920) * 100vw) auto;

    .site-name {
      width: auto;
      svg {
        height: 50px;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    margin-bottom: calc((120 / 1920) * 100vw);

    .icon-twitter {
      height: 28px;
    }

    .icon-youtube {
      height: 36px;
    }

    .icon-studio {
      height: 27px;
    }

    .icon-mgcm {
      height: 27px;
    }
  }
}

.copyright {
  color: var(--white);
  text-align: center;
}

// 768px以上
@media (min-width: 768px) {
  .site-footer {
    padding-bottom: 5vw;

    .site-name {
      width: auto;
      svg {
        height: calc((100 / 1920) * 100vw);
        max-height: 100px;
      }
    }

    &__nav {
      .icon-twitter {
        height: 56px;
      }

      .icon-youtube {
        height: 72px;
      }

      .icon-studio {
        height: 54px;
      }

      .icon-mgcm {
        height: 54px;
      }
    }
  }
}
