@use 'sass:math';
@use 'sass:color';

$white: #fff;
$dark: #090909;
$gray: #454545;
$gray2: #cdcdcd;
$max-width: 1920px;
$content-max-width: 1400px;
$link: #00f6ff;

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

:root {
  --transform-amount: 20px;
  --dark: #{$dark};
  --white: #{$white};
  --gray: #{$gray};
  --gray2: #{$gray2};
  --link: #{$link};
  --link-hover: #{color.change($link, $lightness: 80%)};
  --link-active: #{color.change($link, $lightness: 40%)};
  --bullets-color: #{$white};
  --bullets-count: 4;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  background-color: var(--dark);
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%;
  font-size: 14px;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: -apple-system, BlinkMacSystemFont, a-otf-ud-shin-go-pr6n,
    'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo,
    '游ゴシック  Medium', sans-serif;
  font-weight: 300;
  // OpenTypeのプロポーショナルメトリクスを有効にする
  font-feature-settings: 'palt';

  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &:not(.loaded) {
    .site-header,
    #container,
    .site-footer {
      opacity: 0;
    }
  }
}

@import '_progress';
@import '_header';
@import '_footer';
@import '_kv';
@import '_modal';

#container {
  //max-height: 100vh;
  margin-top: 30px;
  //overflow-y: scroll;
  //scroll-snap-type: y mandatory;
}

.site-main {
  background: var(--dark);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.panel {
  // scroll-snap-align: start;
}

section {
  background-color: var(--dark);
  color: white;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  margin-top: 10vw;
  padding-top: 30px;

  & > h2 {
    line-height: 1;
    margin: 0;
    padding: calc((80 / 1920) * 100vw) 0;
    overflow: hidden;
  }

  &#about > h2 > svg {
    width: calc((936 / 1920) * 100vw);
    max-width: 936px;
  }

  &#art > h2 > svg {
    width: calc((1026 / 1920) * 100vw);
    max-width: 1026px;
  }

  &#xenoscrypto > h2 > svg {
    width: calc((1311 / 1920) * 100vw);
    max-width: 1311px;
  }

  &#object > h2 > svg {
    width: calc((1116 / 1920) * 100vw);
    max-width: 1116px;
  }

  figure {
    display: block;
    margin: 0;
    overflow: hidden;
    position: relative;

    & > img {
      width: 100%;
      height: auto;
      display: block;
    }

    .figure-cover {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &--1 {
        background-color: rgba($dark, 0.7);
      }
      &--2 {
        background-color: rgba($dark, 0.7);
      }
    }
  }

  p {
    overflow: hidden;
    position: relative;
    font-family: a-otf-ud-shin-go-pr6n;
    line-height: 1.5;

    &.small {
      font-size: 0.8rem;
    }

    span.line {
      display: block;
    }

    span.word {
      position: relative;
      display: inline-block;
    }
  }
}

.text {
  margin: 5vw;
}

.btns {
  margin-bottom: 10vw;

  a {
    margin-bottom: 1rem;
  }

  &-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

// Button
.btn {
  text-decoration: none;
  line-height: 2;
  display: inline-block;
  padding: 0;
  cursor: pointer;

  & > svg {
    & > path.bg1 {
      fill: $gray;
      transition: fill 0.3s ease;
    }

    & > path.bg2 {
      fill: $white;
      transition: fill 0.3s ease;
    }

    & > path.font {
      fill: $dark;
      transition: fill 0.3s ease;
    }
  }

  & > svg.btn-lg {
    width: calc((400 / 750) * 100vw);
    max-width: 801px;
  }

  & > svg.btn-md {
    width: calc((400 / 750) * 100vw);
    max-width: 735px;
  }

  &:hover {
    svg {
      & > path.bg1 {
        fill: $white;
      }
      & > path.bg2 {
        fill: #8e8e8e;
      }
    }
  }

  &:active {
    svg {
      & > path.bg1 {
        fill: $white;
      }
      & > path.bg2 {
        fill: $gray;
      }
      & > path.font {
        fill: $white;
      }
    }
  }
}

// Outline Button
.btn-ol {
  & > svg {
    width: calc((400 / 750) * 100vw);
    max-width: 735px;

    & > path.bg {
      stroke: $white;
      fill: $dark;
      transition: all 0.3s ease;
    }
    & > path.font {
      fill: $white;
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    & > svg {
      & > path.bg {
        fill: $gray;
      }
    }
  }

  &:active {
    & > svg {
      & > path.bg {
        fill: #8e8e8e;
      }
    }
  }
}

// 480px以上
@media (min-width: 480px) {
  section {
    p {
      span.line {
        display: inline;
      }
    }
  }
}

// 560px以上
@media (min-width: 560px) {
  section {
    p {
      font-size: calc(16 / 560 * 100vw);

      &.small {
        font-size: calc(12 / 560 * 100vw);
      }
    }
  }
}

// 768px以上
@media (min-width: 768px) {
  #container {
    margin-top: 60px;
  }

  section {
    padding-top: 60px;

    p {
      font-size: calc(20 / 768 * 100vw);

      &.small {
        font-size: calc(14 / 768 * 100vw);
      }
    }
  }

  .text {
    margin-top: math.round(calc((100 / 1920) * 100vw));
    margin-bottom: math.round(calc((100 / 1920) * 100vw));
  }

  .btns {
    margin-bottom: math.round(calc((100 / 1920) * 100vw));

    a {
      margin-bottom: 2rem;
    }
  }

  .btn {
    & > svg.btn-lg {
      width: calc((801 / 1920) * 100vw);
      max-width: 801px;
    }

    & > svg.btn-md {
      width: calc((735 / 1920) * 100vw);
      max-width: 735px;
    }
  }

  // Outline Button
  .btn-ol {
    & > svg {
      width: calc((735 / 1920) * 100vw);
    }
  }
}

// 960px 以上
@media (min-width: 960px) {
  section {
    p {
      font-size: calc(22 / 960 * 100vw);

      &.small {
        font-size: calc(16 / 960 * 100vw);
      }
    }
  }
}

// 1400px 以上
@media (min-width: 1400px) {
  section {
    p {
      font-size: calc(30 / 1920 * 100vw);

      &.small {
        font-size: calc(24 / 1920 * 100vw);
      }
    }
  }
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@import '_slider';
