// キービジュ

.kv {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  & > img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  &__cover {
    display: block;
    position: relative;
    z-index: 0;
    padding-top: math.div(150, 375) * 100vw;
  }

  &__layer {
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    will-change: transform;
  }
}

// .layer-bg {
//   background-image: url('/images/kv/1.jpg');
// }
// .layer-1 {
//   background-image: url('/images/kv/2.png');
// }
// .layer-2 {
//   background-image: url('/images/kv/3.png');
// }
// .layer-3 {
//   background-image: url('/images/kv/4.png');
// }

.layer-4 {
  font-size: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & > svg {
    width: calc((798 / 1920) * 100vw);
    margin-top: math.div(110, 375) * 100vw;
  }
}

// 576px以上
@media (min-width: 576px) {
  .kv {
    &__cover {
      padding-top: math.div(250, 576) * 100vw;
    }
  }

  .layer-4 {
    & > svg {
      margin-top: math.div(180, 576) * 100vw;
    }
  }
}

// 768px以上
@media (min-width: 768px) {
  .kv {
    &__cover {
      padding-top: math.div(320, 768) * 100%;
    }
  }
}

// 1020px以上
@media (min-width: 1020px) {
  .kv {
    &__cover {
      padding-top: math.div(440, 1020) * 100vw;
    }
  }
}

// 1400px以上
@media (min-width: 1400px) {
  .kv {
    &__cover {
      padding-top: math.div(870, 1920) * 100vw;
    }
  }
}
