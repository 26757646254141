.slider {
  width: 100%;
  height: calc((750 / 1920) * 100vw);
  max-height: 750px;
  overflow: hidden;
  position: relative;
}

.slide {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;

  .slide-img {
    width: calc(100% + var(--transform-amount));
    height: calc(100% + var(--transform-amount));
    top: calc(var(--transform-amount) / 2 * -1);
    left: calc(var(--transform-amount) / 2 * -1);
    position: relative;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .img-wrapper {
    width: 100%;
    height: 100%;
    background: var(--blend-color);
  }
}

.slide.current {
  pointer-events: initial;
  opacity: 1;
  z-index: 10;
}

.slide-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.slide-navigation {
  pointer-events: none;
  // position: absolute;
  // bottom: 0;
  z-index: 15;
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: repeat(var(--bullets-count), 30px);
  grid-gap: 8px;
  justify-content: center;
  align-items: center;

  .bullet {
    pointer-events: initial;
    cursor: pointer;
    height: 5px;
    transition: opacity 0.3s ease;
    opacity: 0.2;
    height: 20px;
    background: none;
    outline: none;
    position: relative;
    border: none;
    padding: 0;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 3px);
      background: var(--bullets-color);
      width: 100%;
      height: 3px;
      z-index: 2;
      border-radius: 4px;
      left: 0;
    }
  }

  .bullet.current {
    opacity: 1;
  }

  .bullet:not(.current):hover {
    opacity: 0.5;
  }
}

// 768px以上
@media (min-width: 768px) {
  .slide-navigation {
    grid-template-columns: repeat(var(--bullets-count), 60px);
    grid-gap: 16px;
    height: 50px;

    .bullet {
      height: 30px;

      &:after {
        height: 6px;
      }
    }
  }
}
