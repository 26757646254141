// modal
// 参考：https://getbootstrap.com/docs/5.1/components/modal/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  //transition: opacity 0.15s linear;

  // &:not(.show) {
  //   opacity: 0;
  // }

  &.show {
    //opacity: 1;

    .modal-dialog {
      transform: translate(0, 0);
    }

    .modal-backdrop {
      opacity: 1;
    }
  }

  .btn-close {
    box-sizing: content-box;
    width: 1.5em;
    height: 1.5em;
    padding: 0.25em 0.25em;
    color: var(--white);
    border: 0;
    border-radius: 0.25rem;
    background: none;
    cursor: pointer;

    &:hover {
      color: var(--gray2);
    }

    &:active {
      color: rgba($gray2, 0.5);
    }
  }
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($dark, 0.7);
  opacity: 0;
}

.modal-dialog {
  position: relative;
  z-index: 1;
  width: auto;
  margin: 0.5rem;
  height: calc(100% - 3.5rem);
  //transition: transform 0.3s ease-out 0.2s;
  //transform: translate(0, -50px);

  @media (min-width: 576px) {
    max-width: 70vw;
    margin: 1.75rem auto;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: var(--dark);
  border: 1px solid rgba($gray, 1);
  outline: 0;
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow: hidden;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--gray);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
  margin: 0;
  color: var(--white);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
  text-align: left;
  color: var(--white);

  section {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  p {
    font-size: 1rem;
  }
  .note {
    color: var(--gray2);
  }

  li {
    line-height: 1.5;
  }

  ul {
    padding: 0;

    & > li {
      list-style: none;
      display: flex;

      &::before {
        content: '○';
        display: inline-block;
        margin-right: 0.2rem;
      }
    }
  }

  ol {
    padding: 0;
    & > li {
      counter-increment: counterListInc;
      list-style: none;
      display: flex;
      margin-bottom: 0.3rem;

      &::before {
        content: counter(counterListInc, decimal) '.';
        display: inline-block;
      }
    }
  }

  h3 {
    font-size: 1.5rem;
    border-bottom: solid 1px;
    padding: 0.5rem;
    border-top: solid 1px;
    margin: 2.5rem 0 1rem;
  }

  h4 {
    font-size: 1.25rem;
    margin: 3rem 0 1rem;
  }

  h5,
  h6 {
    font-size: 1.1rem;
    margin: 2rem 0 1rem;
  }

  pre {
    border: solid 1px var(--gray);
    padding: 1rem;
    display: block;
  }

  a {
    color: var(--link);
    &:hover {
      color: var(--link-hover);
    }
    &:active {
      color: var(--link-active);
    }
  }
}
