// progress 読み込み中に出るやつ

$panel-size: 20;

.progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;

  &__bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: flex;

    & > div {
      justify-content: space-around;
      width: 100%;
      height: 100%;
      background-color: var(--dark);
      will-change: transform;
      transform: scaleX(1.03);
    }
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    z-index: 1;
  }

  &__counter {
    color: var(--white);
    margin-top: 1rem;
  }

  &__indicator {
    overflow: hidden;
    transform-origin: center center;

    &__gauge {
      width: 40vw;
      height: auto;

      path:first-of-type {
        color: var(--gray);
      }
      path:last-of-type {
        color: var(--white);
      }

      // rect {
      //   transition: width 1s ease;
      // }
    }

    &__bar {
      background: var(--gray);
      margin-top: 5px;
      width: 100%;
      height: 5px;
      position: relative;

      & > div {
        width: 40%;
        height: 5px;
        background: linear-gradient(90deg, $gray, $gray2, $gray);
        animation: 1s linear 0s infinite running bar;
      }
    }
  }
}

@keyframes bar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(300%);
  }
}
